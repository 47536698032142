/**
 * Simulate fetching data
 * @param {any} dummyData Fake data to be return
 * @param {number} delay miliseconds to wait
 */
export const fetchFakeData = (
  dummyData,
  delay = 1000,
) => new Promise(resolve => setTimeout(() => resolve(dummyData), delay));

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

export const isBrowser = typeof window !== 'undefined';

/**
 * Returns a local path when running on development
 * @param {string} url image URL
 * @returns url
 */
export const handleImageUrls = (url) => {
  if (process.env.NODE_ENV === 'development') return `${process.env.GATSBY_API_URL}${url}`;
  return url;
};

/**
 * A global window object so Gatsby does not crash when building
 */
export const windowGlobal = typeof window !== 'undefined' ? window : {
  localStorage: {
    setItem: () => {},
    getItem: () => {},
  },
};
