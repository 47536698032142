import axios from 'axios';

export const API_URL = process.env.GATSBY_API_URL;

export const BaseApi = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.GATSBY_JWT}`,
  },
});

export const PublicApi = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

BaseApi.interceptors.response.use((res) => res.data);
PublicApi.interceptors.response.use((res) => res.data);
