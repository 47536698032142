import { BaseApi, PublicApi } from './BaseApi';

export const listPhotos = ({ showInHome, showInStore }) =>
  PublicApi.get(`/photos?_sort=created_at&${showInHome ? 'showInHome=true' : ''}${(showInHome && showInStore) ? '&' : ''}${showInStore ? 'showInStore=true' : ''}`, {
    headers: {
      Authorization: '',
    },
  });

export const listPhotosWithSubs = (location) => BaseApi.get(`/photos/subphotos${location ? `/${location}` : ''}`);

export const addPhoto = (body) => BaseApi.post('/photos/add', body, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const editPhoto = (body) => BaseApi.put('/photos/edit', body, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const removePhoto = (id) => BaseApi.delete(`/photos/delete/${id}`);

export const createCheckoutSession = ({
  price = 0,
  quantity = 1,
  name = '',
  imageUrl = '',
}) =>
  BaseApi.post('/create-checkout-session', {
    price,
    quantity,
    name,
    imageUrl,
  });
