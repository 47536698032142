import PictureA from '../images/picture_a_2.jpg';
import Example1 from '../images/example_1.jpg';
import Example2 from '../images/example_2.jpg';
import Example3 from '../images/example_3.jpg';

export const IMAGE_QUALITIES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

// eslint-disable-next-line import/prefer-default-export
export const IMAGE_LIST = [
  {
    image: PictureA,
    label: 'Gramatos',
  },
  {
    image: Example1,
    label: 'Forest',
  },
  {
    image: Example2,
    label: 'Mountains',
  },
  {
    image: Example3,
    label: 'Flowers',
  },
];

export const LOCATIONS = {
  home: 'showInHome',
  store: 'showInStore',
};
