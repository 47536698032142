import { useState, useEffect } from 'react';
// import { fetchFakeData } from '../scripts/utils';
// import { IMAGE_LIST } from '../scripts/constants';
import { listPhotos, listPhotosWithSubs } from '../api/Photos';
// import { LOCATIONS } from '../scripts/constants';

const usePhotos = (config) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const getImages = async () => {
    setLoading(true);
    const data = await listPhotos(config);
    setLoading(false);
    setPhotos(data);
  };

  useEffect(() => {
    getImages();
  }, []);

  return { photos, setPhotos, loading };
};

export default usePhotos;
